export const costOfLivingIndex: { [index: string]: number } = {
  Alabama: 89.1,
  Alaska: 129.9,
  Arizona: 97.7,
  Arkansas: 86,
  California: 151.7,
  Colorado: 117.1,
  Connecticut: 131.8,
  Delaware: 102.6,
  Florida: 99.3,
  Georgia: 92.2,
  Hawaii: 199.9,
  Idaho: 92.4,
  Illinois: 94.6,
  Indiana: 88.3,
  Iowa: 91.1,
  Kansas: 90.2,
  Kentucky: 88.5,
  Louisiana: 93.9,
  Maine: 114.4,
  Maryland: 131.9,
  Massachusetts: 143.2,
  Michigan: 89.3,
  Minnesota: 101.6,
  Mississippi: 84.8,
  Missouri: 88.2,
  Montana: 100.5,
  Nebraska: 92.9,
  Nevada: 108.5,
  "New Hampshire": 117.6,
  "New Jersey": 128.8,
  "New Mexico": 92.8,
  "New York": 139.1,
  "North Carolina": 94.5,
  "North Dakota": 97.2,
  Ohio: 92.8,
  Oklahoma: 88.1,
  Oregon: 131.3,
  Pennsylvania: 101.6,
  "Rhode Island": 119.9,
  "South Carolina": 96.7,
  "South Dakota": 101.1,
  Tennessee: 88.7,
  Texas: 92.6,
  Utah: 97.8,
  Vermont: 116.6,
  Virginia: 104.1,
  Washington: 115.7,
  "West Virginia": 87.8,
  Wisconsin: 97.8,
  Wyoming: 97.1,
};
