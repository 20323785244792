export const boilerplateArray: [string, number][] = [
  ["Housing", 1500],
  ["Transportation", 500],
  ["Food", 300],
  ["Utilities", 300],
  ["Clothing", 50],
  ["Medical", 200],
  ["Insurance", 200],
  ["Supplies", 40],
  ["Personal", 50],
  ["Debt", 100],
  ["Retirement", 200],
  ["Education", 20],
  ["Savings", 100],
  ["Donations", 50],
  ["Entertainment", 50],
];
