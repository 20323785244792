import findChangePasswordErrors from "./findChangePasswordErrors";
import findDelAccErrors from "./findDelAccErrors";
import findLoginErrors from "./findLoginErrors";

const findErrors = {
  findChangePasswordErrors,
  findDelAccErrors,
  findLoginErrors,
};

export default findErrors;
