const url = "https://qbtwo-0ecdac02ca31.herokuapp.com";

const endpoints = {
  signUp: url + "/qbt/api/account/new", //post
  login: url + "/qbt/api/account/login", //post
  remAccount: url + "/qbt/api/account/delete", //post
  changePass: url + "/qbt/api/account/password/reset", //patch
  setExpenses: url + "/qbt/api/expenses/set", //post
  getExpenses: url + "/qbt/api/expenses/get", //post
};

export default endpoints;
